import './App.css';
import logo from './images/logo.png'
import bbq from './images/a.png'

function App() {
  return (
    <div className="App">
    <div className='header'>
      <img src={logo} className='logo'/>
    </div>
     <div className='container'>
      <div>
          <img src={bbq} />
      </div>
      <div className='right'>
        
          <h1> Coming Soon! </h1>
          <p> Our website is currently under construction, and we're working hard to bring you something amazing... </p> 
          <p> Have any questions or need to get in touch? Reach us at </p>
          <p> <b>Phone: </b> (877)659-2619</p>
          <p> <b>Email: </b> sales@badassbbqs.com</p>
      </div>
     </div>
    </div>
  );
}

export default App;
